import Translate from "locale/Translate";

export const Tags = ({ tags = [] }) => (
  <>
    <div>
      <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">
        Tags
      </div>
    </div>
    <div className="clearfix"></div>
    <div className="fh5co_tags_all">
      {tags.map((x) => (
        <a key={x.id} href={`/tag/${x.slug}`} className="fh5co_tagg">
          {x.name}
        </a>
      ))}
      {tags.length === 0 && (
        <p>
          <Translate id="no-news" />
        </p>
      )}
    </div>
  </>
);

export default Tags;
