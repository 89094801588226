import List from "./List";

const News = ({ tag, news, showTags = true, slug, basePath }) => {
  return (
    <div key={slug}>
      <List data={news} showTags={showTags} slug={slug} basePath={basePath} />
    </div>
  );
};

export default News;
