import useWindowSize from "hooks/useWindowSize";
import { getStrapiMedia } from "lib/media";
import { getMobileDetect } from "./getMobileDetect";

export const BannerRectangleHorizontal = ({ banner, userAgent }) => {
  const isMB = getMobileDetect(userAgent)?.isMobile();

  const imageUrl =
    isMB && banner.imageMobile
      ? getStrapiMedia(banner.imageMobile, "origin")
      : getStrapiMedia(banner.image, "origin");
  return (
    <div className="container-fluid pt-2 paddding">
      <div
        className="container paddding"
        style={{ height: isMB ? "calc(100vw / 6)" : "auto" }}
      >
        <a
          href={banner.link}
          target={banner.newTab ? "_blank" : null}
          rel="noreferrer"
        >
          <img
            src={imageUrl}
            format="large"
            style={{ width: "100%" }}
            alt="banner"
          />
        </a>
      </div>
    </div>
  );
};

export const BannerSquare = ({ banner }) => {
  const imageUrl = getStrapiMedia(banner.image, "origin");
  return (
    <div className="pt-5 pb-2">
      <a
        href={banner.link}
        target={banner.newTab ? "_blank" : null}
        rel="noreferrer"
      >
        <img
          src={imageUrl}
          format="large"
          style={{ width: "100%" }}
          alt="banner"
        />
      </a>
    </div>
  );
};

export const BannerArticle = ({ banner, className }) => {
  const { width } = useWindowSize();

  const imageUrl =
    width <= 768 && banner.imageMobile
      ? getStrapiMedia(banner.imageMobile, "origin")
      : getStrapiMedia(banner.image, "origin");
  return (
    <a
      href={banner.link}
      target={banner.newTab ? "_blank" : null}
      rel="noreferrer"
    >
      <img
        src={imageUrl}
        format="large"
        style={{ width: "100%" }}
        alt="banner"
        className={className}
      />
    </a>
  );
};
