import { getStrapiMedia } from "lib/media";
import { formatTime } from "lib/utils";
import Translate, { useLanguageContext } from "locale/Translate";
import Link from "next/link";

const Card = ({ image, date, title, slug }) => {
  return (
    <div className="row pb-3">
      <div className="col-5 align-self-center">
        <div className="fh5co_most_trading">
          <Link href={"/" + slug}>
            <img src={image} alt="img" loading="lazy" />
          </Link>
        </div>
      </div>
      <div className="col-7 paddding">
        <div className="most_fh5co_treding_font truncate_text_2">
          <Link href={"/" + slug}>
            <a>{title}</a>
          </Link>
        </div>
        <div className="most_fh5co_treding_font_123">{date}</div>
      </div>
    </div>
  );
};
export const MostPopular = ({ most_popular = {} }) => {
  const listData = most_popular?.articles || [];
  const { locale } = useLanguageContext();
  return (
    <>
      <div>
        <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">
          <Translate id="most-popular-title" />
        </div>
      </div>
      {listData.map((x, index) => {
        const image = getStrapiMedia(x.image, "thumbnail");
        const title = x?.title;
        const date = formatTime(x?.created_at || x?.published_at, locale);
        const slug = x?.slug;
        return (
          <Card
            key={x.id}
            index={index}
            image={image}
            title={title}
            date={date}
            slug={slug}
          />
        );
      })}
    </>
  );
};

export default MostPopular;
