import Paging from "./Paging";
import Tags from "./Tags";
import MostPopular from "./MostPopular";
import { useCallback, useEffect, useState } from "react";
import { formatTime } from "lib/utils";
import { getStrapiMedia } from "lib/media";
import { useRouter } from "next/router";
import Link from "next/link";
import Translate, { useLanguageContext } from "locale/Translate";
import { getAsync } from "lib/request";
import { useGlobalContext } from "pages/_app";
import { BannerSquare } from "components/Shared/Banner";

const Card = ({
  image,
  date,
  title,
  description,
  slug,
  category,
  linkCategory,
}) => (
  <div className="row pb-4">
    <div className="col-md-5">
      <div className="fh5co_hover_news_img">
        <div className="fh5co_news_img">
          <img src={image} alt={image} loading="lazy" />
        </div>
        <div></div>
      </div>
    </div>
    <div className="col-md-7 animate-box">
      <Link href={`/${slug}`}>
        <a className="fh5co_magna py-2">{title}</a>
      </Link>
      <p className="pt-2">
        {category && (
          <Link href={`/category/${linkCategory}`}>
            <a className="fh5co_mini_time fh5co_mini_time_category">
              {category}
            </a>
          </Link>
        )}
        <Link href={`/${slug}`}>
          <a className="fh5co_mini_time">{date}</a>
        </Link>
      </p>
      <div className="fh5co_consectetur">{description}</div>
    </div>
  </div>
);
const CardTop = ({
  image,
  date,
  title,
  description,
  slug,
  category,
  linkCategory,
}) => (
  <div className="articles-top">
    <Link href={`/${slug}`}>
      <img className="image-article-top" src={image} alt="img" />
    </Link>
    <div className="content-article-top">
      <div className="d-flex info-article-top">
        <Link href={`/category/${linkCategory}`}>
          <span>{category}</span>
        </Link>
        <span>{date}</span>
      </div>
      <Link href={`/${slug}`}>
        <p className="title-article-top">{title}</p>
      </Link>
      <div className="des-article-top">{description}</div>
    </div>
  </div>
);
export const List = ({ slug, data, showTags = true }) => {
  const { locale } = useLanguageContext();
  const [dataListNews, setDataListNews] = useState(data);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const totalData = dataListNews?.total;
  const fullData = dataListNews?.articles?.length === totalData;

  const { banner_home285x285 } = useGlobalContext();

  useEffect(() => {
    if (currentPageNumber !== 0) {
      handleLoadingData();
    }
  }, [currentPageNumber]);

  const handleLoadingData = async () => {
    const dataLoading = await getAsync("/api/news", {
      page: currentPageNumber,
      slug: slug,
    });
    const articlesData = dataLoading.data.data;
    setDataListNews((x) => ({
      ...x,
      articles: [...x.articles, ...articlesData],
    }));
  };
  const handleNextCurrentpage = () => {
    if (fullData) {
      return;
    }
    setCurrentPageNumber((x) => x + 1);
  };

  return (
    <div className="container-fluid pb-4 pt-4 paddding">
      <div className="container paddding">
        <div className="row mx-0">
          <div
            className="col-md-8 animate-box"
            data-animate-effect="fadeInLeft"
          >
            <div>
              <div className="fh5co_heading fh5co_heading_border_bottom py-2 mb-4">
                {dataListNews?.category?.name || <Translate id="news-title" />}
              </div>
            </div>
            {(dataListNews.articles || []).map((x, index) => {
              const image = getStrapiMedia(x.image, "large");
              const title = x?.title;
              const date = formatTime(x?.created_at || x?.published_at, locale);
              const desc = x?.description;
              const slug = x?.slug;
              const name_category = x?.category?.name;
              const linkCategory = x?.category?.slug;
              return (
                <>
                  {index === 0 ? (
                    <CardTop
                      key={x.id}
                      index={index}
                      image={image}
                      title={title}
                      date={date}
                      description={desc}
                      slug={slug}
                      category={name_category}
                      linkCategory={linkCategory}
                    />
                  ) : (
                    <Card
                      key={x.id}
                      index={index}
                      image={image}
                      title={title}
                      date={date}
                      description={desc}
                      slug={slug}
                      category={name_category}
                      linkCategory={linkCategory}
                    />
                  )}
                </>
              );
            })}
            {!fullData && (
              <div
                className="button_load_more"
                onClick={() => handleNextCurrentpage()}
              >
                <Translate id="load-more" />
              </div>
            )}
          </div>
          <div
            className="col-md-3 animate-box"
            data-animate-effect="fadeInRight"
          >
            {banner_home285x285 && <BannerSquare banner={banner_home285x285} />}
            <MostPopular most_popular={dataListNews?.mostPopular} />
            {showTags && <Tags tags={dataListNews?.tags} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
